<template>
  <div class="page-wrap">
    <div class="page-header">
      <div class="search-wrap">
        <searchInput v-model="baseData.query" @search="searchEvent"></searchInput>
      </div>
      <button type="button" class="filter-btn" @click="targetFilterEvent">
        筛选
        <van-icon name="arrow-down" />
      </button>
    </div>

    <filterPopup
      ref="filterPopup"
      height="50%"
      title="记录筛选"
      @onReset="onReset"
      @onConfirm="searchEvent"
    >
      <div class="filter-container">
        <filterPopupItem label="记录时间">
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in baseOptions.filterTimeOptions"
              :key="index"
              :class="{ active: index === baseData.activeFilterTimeIndex }"
              @click="targetFilterTime(index, item)"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="time-range-wrap">
            <dateTimeRange
              v-model="baseData.date"
              :placeholder="['开始日期', '结束日期']"
              @change="dateChange"
            ></dateTimeRange>
          </div>
        </filterPopupItem>
      </div>
    </filterPopup>

    <tabs1 v-model="activeTab" :options="tabList" @change="tabChange"></tabs1>

    <div class="page-container"><router-view /></div>
  </div>
</template>

<script>
import dateTimeRange from "@components/dateTimeRange";
import { getDate } from "@utils/utils.js";
export default {
  name: "record",
  components: { dateTimeRange },
  provide() {
		return {
			deviceRecordBaseData: this.baseData
		};
	},
  data() {
    return {
      baseData: {
        query: "",
        date: "",
        activeFilterTimeIndex: 2,
      },
      baseOptions: {
        filterTimeOptions: [
          {
            label: "近一周",
            startTime: new Date(
              getDate(new Date().getTime() - 7 * 8.64e7) + " 00:00:00"
            ).getTime(),
            endTime: new Date(getDate() + " 23:59:59").getTime(),
          },
          {
            label: "近两周",
            startTime: new Date(
              getDate(new Date().getTime() - 14 * 8.64e7) + " 00:00:00"
            ).getTime(),
            endTime: new Date(getDate() + " 23:59:59").getTime(),
          },
          {
            label: "近1个月",
            startTime: new Date(
              getDate(new Date().getTime() - 30 * 8.64e7) + " 00:00:00"
            ).getTime(),
            endTime: new Date(getDate() + " 23:59:59").getTime(),
          },
        ],
      },
      activeTab: "deviceRecord/reservation",
      tabList: [
        {
          title: "预约记录",
          path: "/deviceRecord/reservation",
        },
        {
          title: "借用记录",
          path: "/deviceRecord/borrow",
        },
        {
          title: "报障记录",
          path: "/deviceRecord/breakdown",
        },
        {
          title: "费用记录",
          path: "/deviceRecord/cost",
        },
      ],
    };
  },
  created() {
    this.activeTab = this.$route.path;
  },
  mounted() {},
  methods: {
    searchEvent() {
			this.$refs.filterPopup.hide();
			this.$bus.emit('deviceRecordSearch');
		},
    targetFilterEvent() {
      this.$refs.filterPopup.show();
    },
    targetFilterTime(index) {
      if (this.baseData.activeFilterTimeIndex === index)
        this.baseData.activeFilterTimeIndex = "";
      else this.baseData.activeFilterTimeIndex = index;

      this.baseData.date = "";
    },
    dateChange() {
      this.baseData.activeFilterTimeIndex = "";
    },
    onReset() {
      this.baseData= {
        query: "",
        date: "",
        activeFilterTimeIndex: "",
      }
    },
    onConfirm() {
       this.$refs.filterPopup.hide();
    },
    tabChange(e) {
      this.activeTab = e;
      this.$router.replace(e).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";
@import "@styles/layout.scss";
.page-wrap {
  height: 100%;
  background: linear-gradient(
    360deg,
    rgba(114, 171, 253, 0) 0%,
    #568efc 70%,
    #3e73fb 100%
  );
  background-size: 100% 195px;
  background-repeat: no-repeat;

  .page-header {
    margin-bottom: 12px;
    padding: 8px 16px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-wrap {
      width: calc(100% - 50px);
    }
    .filter-btn {
      font-size: 12px;
      color: #fff;
      background: none;
      line-height: 32px;
      .van-icon {
        margin-left: 4px;
      }
    }
  }

  .page-container {
    height: calc(100% - 32px - 52px);
    padding: 12px 0;
  }
}

.filter-container {
  .list {
    display: flex;
    justify-content: space-between;
    .list-item {
      width: 30%;
      text-align: center;
      line-height: 30px;
      border-radius: 32px;
      background: #eee;
      border: 1px solid #eee;
      font-size: 14px;
      color: #333;
      transition: all 0.2s linear;
      &.active {
        background: #d8e3ff;
        color: #3e73fb;
        border-color: #3e73fb;
      }
    }
  }
  .time-range-wrap {
    margin-top: 12px;
  }
}
</style>
